import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function NavbarItem({ item, isSticky, t }) {
    const location = useLocation();
    const isHomePage = location.pathname === '/';

    const handleClick = (e, href) => {
        if (isHomePage) {
            e.preventDefault();
            // Scroll to the element with the ID equal to `href`
            document.getElementById(href)?.scrollIntoView({ behavior: 'smooth' });
        }
        // Otherwise, let the Link component handle the navigation
    };

    return (
        <li key={item.href} className="group relative lg:flex-shrink-0">
            <Link
                to={isHomePage ? `#${item.href}` : `/${item.href}`}
                onClick={(e) => handleClick(e, item.href)}
                className={`ud-menu-scroll block py-2 px-8 text-base font-medium lg:inline-flex lg:py-6 lg:px-2 xl:px-4 lg:text-sm xl:text-base lg:whitespace-nowrap lg:group-hover:opacity-70 ${
                    isSticky ? 'text-black dark:text-white' : 'text-white dark:text-white'
                }`}
            >
                {t(item.label)}
            </Link>
        </li>
    );
}
