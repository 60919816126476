import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {

    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="relative overflow-hidden bg-primary pt-[120px] md:pt-[130px] lg:pt-[160px] pb-[120px] md:pb-[130px] lg:pb-[160px]">
            <div className="max-w-3xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
                <div className="bg-blue-300 py-6 px-8">
                    <h1 className="text-3xl font-bold text-white">{t("privacy-policy.privacyPolicy")}</h1>
                </div>
                <div className="p-8">
                    <p className="mb-6 text-gray-700">
                        {t("privacy-policy.introduction")}
                    </p>
                    <h2 className="text-2xl font-semibold text-blue-800 mb-4">{t("privacy-policy.informationWeCollect")}</h2>
                    <ul className="list-disc list-inside mb-6 text-gray-700">
                        <li>{t("privacy-policy.info1")}</li>
                        <li>{t("privacy-policy.info2")}</li>
                        <li>{t("privacy-policy.info3")}</li>
                    </ul>
                    <h2 className="text-2xl font-semibold text-blue-800 mb-4">{t("privacy-policy.howWeUseInfo")}</h2>
                    <ul className="list-disc list-inside mb-6 text-gray-700">
                        <li>{t("privacy-policy.how1")}</li>
                        <li>{t("privacy-policy.how2")}</li>
                        <li>{t("privacy-policy.how3")}</li>
                    </ul>
                    <h2 className="text-2xl font-semibold text-blue-800 mb-4">{t("privacy-policy.dataSecurity")}</h2>
                    <p className="mb-6 text-gray-700">
                        {t("privacy-policy.data1")}
                    </p>
                    <h2 className="text-2xl font-semibold text-blue-800 mb-4">{t("privacy-policy.yourRights")}</h2>
                    <p className="mb-6 text-gray-700">
                        {t("privacy-policy.right1")}
                    </p>
                    <h2 className="text-2xl font-semibold text-blue-800 mb-4">{t("privacy-policy.policyChanges")}</h2>
                    <p className="mb-6 text-gray-700">
                        {t("privacy-policy.changes")}
                    </p>
                    <h2 className="text-2xl font-semibold text-blue-800 mb-4">{t("privacy-policy.contactUs")}</h2>
                    <p className="text-gray-700">
                        {t("privacy-policy.contactInfo")}
                    </p>
                </div>
                <div className="bg-blue-100 py-4 px-8 text-center">
                    <p className="text-sm text-blue-800">
                        &copy; {t("privacy-policy.allRightsReserved")}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;