import { useState, useEffect } from "react";
import Hero from "../components/Hero";
import Features from "../components/Features";
import About from "../components/About";
import Cta from "../components/Cta";
import Pricing from "../components/Pricing";
import Faq from "../components/Faq";
import Blog from "../components/Blog";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import {useTranslation} from "react-i18next";

const Home = () => {
    const { t } = useTranslation()
    const [showContactLink, setShowContactLink] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const scrollBottom = scrollTop + windowHeight;

            // Define a threshold (e.g., 100px from the bottom)
            const threshold = 100;

            if (documentHeight - scrollBottom < threshold) {
                setShowContactLink(false);
            } else {
                setShowContactLink(true);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <>
            <Hero/>
            <Features/>
            <About/>
            <Cta/>
            <Pricing/>
            <Faq/>
            <Blog/>
            <Contact/>
            <Footer/>
            {showContactLink && (

                <a className="fixed bottom-5 left-4 z-[999] inline-flex items-center gap-[10px] rounded-lg bg-white px-[14px] py-2 shadow-2 dark:bg-dark-2 sm:left-9"
                href={"#contact"}
                >
                <span className="text-base font-medium text-dark-3 dark:text-dark-6">
                {t('floatingText')}
                </span>
                </a>
                )}
</>
);
}

export default Home;