import { useTranslation } from "react-i18next";

export default function Pricing() {
    const { t } = useTranslation();

    return (
        <section
            id="pricing"
            className="relative z-20 overflow-hidden bg-white pb-12 pt-20 dark:bg-dark lg:pb-[90px] lg:pt-[120px]"
        >
            <div className="container mx-auto">
                <div className="-mx-4 flex flex-wrap">
                    <div className="w-full px-4">
                        <div className="mx-auto mb-[60px] max-w-[510px] text-center">
                            <span className="mb-2 block text-lg font-semibold text-primary">
                                {t("pricing.label")}
                            </span>
                            <h2
                                className="mb-3 text-3xl font-bold text-dark dark:text-white sm:text-4xl md:text-[40px] md:leading-[1.2]"
                            >
                                {t("pricing.heading")}
                            </h2>
                            <p className="text-base text-body-color dark:text-dark-6">
                                {t("pricing.subheading")}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    <div className="px-4">
                        <div
                            className="relative z-10 mb-10 overflow-hidden rounded-xl bg-white px-8 py-10 shadow-pricing dark:bg-dark-2 sm:p-12 lg:px-6 lg:py-10 xl:p-14 h-full flex flex-col justify-between"
                        >
                            <div>
                                <span className="mb-5 block text-xl font-medium text-dark dark:text-white">
                                    Basic
                                </span>
                                <h2 className="mb-11 text-4xl font-semibold text-dark dark:text-white xl:text-[42px] xl:leading-[1.21]">
                                    <span className="-ml-1 -tracking-[2px]">50</span>
                                    <span className="font-medium"> PLN</span>
                                    <span className="text-base font-normal text-body-color dark:text-dark-6">
                                        {t("pricing.per-month")}
                                    </span>
                                </h2>
                            </div>
                            <div className="mb-[50px] flex-grow">
                                <h5 className="mb-5 text-lg font-medium text-dark dark:text-white">
                                    {t("pricing.features")}
                                </h5>
                                <div className="flex flex-col gap-[14px]">
                                    <p className="text-base text-body-color dark:text-dark-6">
                                        {t("pricing.starter.feature1")}
                                    </p>
                                    <p className="text-base text-body-color dark:text-dark-6">
                                        {t("pricing.starter.feature2")}
                                    </p>
                                    <p className="text-base text-body-color dark:text-dark-6">
                                        {t("pricing.starter.feature3")}
                                    </p>
                                    <p className="text-base text-body-color dark:text-dark-6">
                                        {t("pricing.starter.feature4")}
                                    </p>
                                </div>
                            </div>
                            <div>
                                <a
                                    href={"#contact"}
                                    className="inline-block rounded-md bg-primary px-7 py-3 text-center text-base font-medium text-white transition hover:bg-blue-dark"
                                >
                                    {t("pricing.button")}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="px-4">
                        <div
                            className="relative z-10 mb-10 overflow-hidden rounded-xl bg-white px-8 py-10 shadow-pricing dark:bg-dark-2 sm:p-12 lg:px-6 lg:py-10 xl:p-14 h-full flex flex-col justify-between"
                        >
                            <p
                                className="absolute right-[-50px] top-[60px] inline-block -rotate-90 rounded-bl-md rounded-tl-md bg-primary px-5 py-2 text-base font-medium text-white"
                            >
                                {t("pricing.recommended")}
                            </p>
                            <div>
                                <span className="mb-5 block text-xl font-medium text-dark dark:text-white">
                                    Premium
                                </span>
                                <h2 className="mb-11 text-4xl font-semibold text-dark dark:text-white xl:text-[42px] xl:leading-[1.21]">
                                    <span className="-ml-1 -tracking-[2px]">70</span>
                                    <span className="font-medium"> PLN</span>
                                    <span className="text-base font-normal text-body-color dark:text-dark-6">
                                        {t("pricing.per-month")}
                                    </span>
                                </h2>
                            </div>
                            <div className="mb-[50px] flex-grow">
                                <h5 className="mb-5 text-lg font-medium text-dark dark:text-white">
                                    {t("pricing.features")}
                                </h5>
                                <div className="flex flex-col gap-[14px]">
                                    <p className="text-base text-body-color dark:text-dark-6">
                                        {t("pricing.basic.feature1")}
                                    </p>
                                    <p className="text-base text-body-color dark:text-dark-6">
                                        {t("pricing.basic.feature2")}
                                    </p>
                                    <p className="text-base text-body-color dark:text-dark-6">
                                        {t("pricing.basic.feature3")}
                                    </p>
                                </div>
                            </div>
                            <div>
                                <a
                                    href={"#contact"}
                                    className="inline-block rounded-md bg-primary px-7 py-3 text-center text-base font-medium text-white transition hover:bg-blue-dark"
                                >
                                    {t("pricing.button")}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="px-4">
                        <div
                            className="relative z-10 mb-10 overflow-hidden rounded-xl bg-white px-8 py-10 shadow-pricing dark:bg-dark-2 sm:p-12 lg:px-6 lg:py-10 xl:p-14 h-full flex flex-col justify-between"
                        >
                            <div>
                                <span className="mb-5 block text-xl font-medium text-dark dark:text-white">
                                    Enterprise
                                </span>
                                <h2 className="mb-11 text-4xl font-semibold text-dark dark:text-white xl:text-[42px] xl:leading-[1.21]">
                                    <span className="-ml-1 -tracking-[2px]">{t("pricing.enterprise.ask")}</span>
                                    <span className="font-medium"> </span>
                                </h2>
                            </div>
                            <div className="mb-[50px] flex-grow">
                                <h5 className="mb-5 text-lg font-medium text-dark dark:text-white">
                                    {t("pricing.features")}
                                </h5>
                                <div className="flex flex-col gap-[14px]">
                                    <p className="text-base text-body-color dark:text-dark-6">
                                        {t("pricing.enterprise.feature1")}
                                    </p>
                                    <p className="text-base text-body-color dark:text-dark-6">
                                        {t("pricing.enterprise.feature2")}
                                    </p>
                                    <p className="text-base text-body-color dark:text-dark-6">
                                        {t("pricing.enterprise.feature3")}
                                    </p>
                                    <p className="text-base text-body-color dark:text-dark-6">
                                        {t("pricing.enterprise.feature4")}
                                    </p>
                                </div>
                            </div>
                            <div>
                                <a
                                    href={"#contact"}
                                    className="inline-block rounded-md bg-primary px-7 py-3 text-center text-base font-medium text-white transition hover:bg-blue-dark"
                                >
                                    {t("pricing.enterprise.button")}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
