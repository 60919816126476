import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageChanger = ({ isSticky }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const { i18n } = useTranslation();

    const toggleDropdown = () => setShowDropdown(!showDropdown);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setShowDropdown(false);
    };

    return (
        <div className="language-changer">
            <button
                className={`language-btn ${isSticky ? 'text-black dark:text-white' : 'text-white'}`}
                onClick={toggleDropdown}
            >
                {i18n.language.toUpperCase()}
            </button>
            {showDropdown && (
                <div className="dropdown">
                    <button
                        className="dropdown-item"
                        onClick={() => changeLanguage("en")}
                    >
                        EN
                    </button>
                    <button
                        className="dropdown-item"
                        onClick={() => changeLanguage("pl")}
                    >
                        PL
                    </button>
                </div>
            )}
            <style jsx>{`
                .language-changer {
                    position: relative;
                    display: inline-block;
                }
                .language-btn {
                    border: none;
                    padding: 10px;
                    border-radius: 4px;
                    cursor: pointer;
                    font-size: 16px;
                }
                .dropdown {
                    position: absolute;
                    top: 100%;
                    right: 0;
                    background-color: white;
                    border: 1px solid #ddd;
                    border-radius: 4px;
                    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
                    z-index: 1000;
                }
                .dropdown-item {
                    display: block;
                    padding: 10px;
                    color: #333;
                    text-align: left;
                    border: none;
                    background: none;
                    cursor: pointer;
                    font-size: 16px;
                }
                .dropdown-item:hover {
                    background-color: #f1f1f1;
                }
            `}</style>
        </div>
    );
}

export default LanguageChanger;