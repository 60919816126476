import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as NavbarLogoLight } from '../images/navbarLogoLight.svg';
import { ReactComponent as NavbarLogoDark } from '../images/navbarLogoDark.svg';

export default function NavbarLogo({ isSticky }) {
    const location = useLocation();
    const isHomePage = location.pathname === '/';

    const handleClick = (e) => {
        if (isHomePage) {
            e.preventDefault();
            document.getElementById('home')?.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="flex items-center justify-start w-[250px]">
            <Link
                className={`p-5 font-bold ${
                    isSticky ? 'text-black dark:text-white' : 'text-white dark:text-white'
                }`}
                to="/"
                onClick={handleClick}
            >
                {isSticky ? (
                    <>
                        <NavbarLogoDark className="dark:hidden" width={50} height={50} />
                        <NavbarLogoLight className="hidden dark:block" width={50} height={50} />
                    </>
                ) : (
                    <NavbarLogoLight width={50} height={50} />
                )}
            </Link>
        </div>
    );
}