
export default function Template({ link, image, techStack }) {
    return (
        <div className="px-4">
            <div className="wow fadeInUp group mb-10" data-wow-delay=".1s">
                <div className="mb-8 overflow-hidden rounded-[5px] img-container">
                    <a href={link} target="_blank" rel='noreferrer'
                       className="block">
                        <img
                            src={image}
                            alt="blog1"
                            className="w-full h-full object-cover transition group-hover:rotate-6 group-hover:scale-125"
                        />
                    </a>
                </div>
                <div>
        <span
            className="mb-6 inline-block rounded-[5px] bg-primary px-4 py-0.5 text-center text-xs font-medium leading-loose text-white">
          {techStack}
        </span>
                </div>
            </div>
        </div>
    );
}