import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';

const TermsOfService = () => {

    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="relative overflow-hidden bg-primary pt-[120px] md:pt-[130px] lg:pt-[160px] pb-[120px] md:pb-[130px] lg:pb-[160px]">
            <div className="max-w-3xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
                <div className="bg-blue-300 py-6 px-8">
                    <h1 className="text-3xl font-bold text-white">{t("terms-of-service.termsOfService")}</h1>
                </div>
                <div className="p-8">
                    <p className="mb-6 text-gray-700">
                        {t("terms-of-service.introduction")}
                    </p>
                    <h2 className="text-2xl font-semibold text-blue-800 mb-4">1. {t("terms-of-service.serviceDescription")}</h2>
                    <p className="mb-6 text-gray-700">
                        {t("terms-of-service.description")}
                    </p>
                    <h2 className="text-2xl font-semibold text-blue-800 mb-4">2. {t("terms-of-service.userResponsibilities")}</h2>
                    <ul className="list-disc list-inside mb-6 text-gray-700">
                        <li>{t("terms-of-service.responsibility1")}</li>
                        <li>{t("terms-of-service.responsibility2")}</li>
                        <li>{t("terms-of-service.responsibility3")}</li>
                    </ul>
                    <h2 className="text-2xl font-semibold text-blue-800 mb-4">3. {t("terms-of-service.subscriptionAndPayments")}</h2>
                    <p className="mb-6 text-gray-700">
                        {t("terms-of-service.subsAndPaysDescription")}
                    </p>
                    <h2 className="text-2xl font-semibold text-blue-800 mb-4">4. {t("terms-of-service.intellectualProperty")}</h2>
                    <p className="mb-6 text-gray-700">
                        {t("terms-of-service.intelPropertyDescription")}
                    </p>
                    <h2 className="text-2xl font-semibold text-blue-800 mb-4">5. {t("terms-of-service.limitationOfLiability")}</h2>
                    <p className="mb-6 text-gray-700">
                        {t("terms-of-service.limitLiabilityDescription")}
                    </p>
                    <h2 className="text-2xl font-semibold text-blue-800 mb-4">6. {t("terms-of-service.termination")}</h2>
                    <p className="mb-6 text-gray-700">
                        {t("terms-of-service.terminationDescription")}
                    </p>
                    <h2 className="text-2xl font-semibold text-blue-800 mb-4">7. {t("terms-of-service.changesToTerms")}</h2>
                    <p className="mb-6 text-gray-700">
                        {t("terms-of-service.changesDescription")}
                    </p>
                    <h2 className="text-2xl font-semibold text-blue-800 mb-4">8. {t("terms-of-service.contactUs")}</h2>
                    <p className="text-gray-700">
                        {t("terms-of-service.contactDescription")}
                    </p>
                </div>
                <div className="bg-blue-100 py-4 px-8 text-center">
                    <p className="text-sm text-blue-800">
                        &copy; {t("terms-of-service.allRightsReserved")}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TermsOfService;