export const NavbarItems= [
    {
        href: 'home',
        label: 'navbar.home'
    },
    {
      href: "features",
      label: "navbar.features"
    },
    {
        href: 'about',
        label: 'navbar.about'
    },
    {
        href: 'pricing',
        label: 'navbar.pricing'
    },
    // {
    //     href: 'testimonials',
    //     label: 'navbar.testimonials'
    // },
    {
        href: 'faq',
        label: 'navbar.faq'
    },
    {
        href: 'templates',
        label: 'navbar.templates'
    },
    {
        href: 'contact',
        label: 'navbar.contact'
    },
]