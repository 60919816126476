import blogImage1 from '../images/mainHeroImage.png'
import blogImage2 from '../images/theme2.png'
import blogImage3 from '../images/theme3.png'
import barber1 from "../images/barber1.png"
import barber2 from "../images/barber2.png"
import restaurant1 from "../images/restaurant1.png"
import {useTranslation} from "react-i18next";
import Template from "./Template";


const templates = [
    {
        link: "https://dental-template-1.web.app/",
        image: blogImage1,
        techStack: "React, Tailwind"
    },
    {
        link: "https://demo.misbahwp.com/dentist-dental-care/",
        image: blogImage2,
        techStack: "HTML, CSS"
    },
    {
        link: "https://dental-template-2.web.app/",
        image: blogImage3,
        techStack: "HTML, CSS, JS"
    },
    {
        link: "https://themewagon.github.io/brber/index.html",
        image: barber1,
        techStack: "HTML, CSS, JS"
    },
    {
        link: "https://themewagon.github.io/alotan/index.html",
        image: barber2,
        techStack: "HTML, CSS, JS"
    },
    {
        link: "https://themewagon.github.io/restoran/",
        image: restaurant1,
        techStack: "HTML, CSS, JS, BOOTSTRAP"
    },
]

export default function Blog() {

    const { t } = useTranslation()

    return (
        <section className="bg-white pb-10 pt-20 dark:bg-dark lg:pb-20 lg:pt-[120px]" id="templates">
            <div className="container mx-auto">
                <div className="-mx-4 flex flex-wrap justify-center">
                    <div className="w-full px-4">
                        <div className="mx-auto mb-[60px] max-w-[485px] text-center">
              <span className="mb-2 block text-lg font-semibold text-primary">
                {t('template.title')}
              </span>
                            <h2
                                className="mb-4 text-3xl font-bold text-dark dark:text-white sm:text-4xl md:text-[40px] md:leading-[1.2]"
                            >
                                {t('template.heading')}
                            </h2>
                            <p className="text-base text-body-color dark:text-dark-6">
                                {t('template.subheading')}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                    {templates.map((template, index) => (
                        <Template
                            key={index}
                            link={template.link}
                            image={template.image}
                            techStack={template.techStack}
                        />
                    ))}
                </div>

            </div>
        </section>
    );
}