import './App.css';
import { Suspense, useEffect } from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Features from "./components/Features";
import Pricing from "./components/Pricing";
import Faq from "./components/Faq";
import Blog from "./components/Blog";
import TermsOfService from "./pages/TermsOfService";

function App() {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true,
            offset: 200,
        });
    }, []);

    return (
        <Router>
            <Suspense fallback="loading">
                <Navbar/>
                <Routes>
                    <Route path="/home" element={<Navigate to="/" replace />} />
                    <Route path="/" element={<Home/>}/>
                    <Route path="/about" element={<About/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="/features" element={<Features/>}/>
                    <Route path="/pricing" element={<Pricing/>}/>
                    <Route path="/faq" element={<Faq/>}/>
                    <Route path="/templates" element={<Blog/>}/>
                    <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                    <Route path="/terms-of-service" element={<TermsOfService/>}/>
                </Routes>
            </Suspense>
        </Router>
    );
}

export default App;
